import axios from "axios";

import ENDPOINTS from "./endpoints";

const LineApi = {
  async updateText(folderId, lines) {
    const linesToUpdate = [];
    for (let i = 0; i < lines.length; i++) {
      const { id, text, index } = lines[i];
      const imageId = lines[0]?.imageID;

      const polygon = lines[i]?.polygon ? lines[i]?.polygon : [];
      const baseline = lines[i]?.baseline ? lines[i]?.baseline : [];
      const isFlipped = lines[i]?.isFlipped ?? false;

      linesToUpdate.push({
        imageID: imageId,
        index: index,
        order: i,
        text: text ? text : " ",
        polygon: polygon ? polygon : [],
        baseline: baseline ? baseline : [],
        isFlipped,
      });
    }
    try {
      const url = ENDPOINTS.UPDATE_TEXT(folderId);
      const response = await axios.put(url, linesToUpdate);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async restoreVersion(folderId, lines) {
    const linesToUpdate = [];
    for (let i = 0; i < lines.length; i++) {
      const { id, text, index } = lines[i];
      const imageId = lines[0]?.imageID;

      const polygon = lines[i]?.polygon ? lines[i]?.polygon : [];
      const baseline = lines[i]?.baseline ? lines[i]?.baseline : [];
      const isFlipped = lines[i]?.isFlipped ?? false;

      linesToUpdate.push({
        imageID: imageId,
        index: index,
        order: i,
        text: text ? text : " ",
        polygon: polygon ? polygon : [],
        baseline: baseline ? baseline : [],
        isFlipped,
      });
    }
    try {
      const url = ENDPOINTS.RESTORE_VERSION(folderId);
      const response = await axios.put(url, linesToUpdate);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async tashkeelText({ texts, strategy }) {
    try {
      const url = ENDPOINTS.TASHKEEL_TEXT();
      const response = await axios.post(url, { texts, strategy });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async historyByDate(folderId, imageId, params) {
    try {
      const url = ENDPOINTS.LINE_HISTORY(folderId, imageId);
      const response = await axios.get(url, { params });
      return response.data.history;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getLinesByImageId(folderId, documentId, imageId) {
    try {
      const url = ENDPOINTS.LINES(folderId, documentId, imageId);
      const response = await axios.get(url);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async addNewBaseline(imageId, body) {
    try {
      const url = ENDPOINTS.ADD_BASELINE(imageId);
      const response = await axios.post(url, body);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async editBaseline(lineId, imageId, body) {
    try {
      const url = ENDPOINTS.EDIT_BASELINE(lineId, imageId);
      const response = await axios.put(url, body);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async deleteBaseline(lineId, imageId) {
    try {
      const url = ENDPOINTS.DELETE_BASELINE(lineId, imageId);
      const response = await axios.delete(url);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateLineRotationStatus(lineId, imageId, isFlipped) {
    try {
      const url = ENDPOINTS.UPDATE_LINE_ROTATION_STATUS(
        lineId,
        imageId,
        isFlipped
      );
      const response = await axios.put(url);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getMLModels() {
    try {
      const url = ENDPOINTS.ML_MODELS();
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default LineApi;
